<template lang="pug">
  div.policy
    div.container
      h1 {{ $t('terms.title') }}
      .policy__container
        div(v-html="$t('terms.welcomeText', { link: `<a href='https://easypon.in/${ $i18n.locale }' class='custom-link' target='_blank'>https://easypon.in/${ $i18n.locale }</a>` })")
        h3 {{ $t('terms.eligibilityTitle') }}
        p {{ $t('terms.eligibilityText') }}
        h3 {{ $t('terms.policyTitle') }}
        p {{ $t('terms.policyText') }}
        h3 {{ $t('terms.propertyTitle') }}
        p {{ $t('terms.propertyText') }}
        h3 {{ $t('terms.conductTitle') }}
        p {{ $t('terms.conductText') }}
        ul
          div(v-html="$t('terms.conductList')")
        p {{ $t('terms.reserveText') }}
        h3 {{ $t('terms.disclaimerTitle') }}
        div(v-html="$t('terms.disclaimerText')")
        h3 {{ $t('terms.limitationTitle') }}
        p {{ $t('terms.limitationText') }}
        h3 {{ $t('terms.indemnificationTitle') }}
        p {{ $t('terms.indemnificationText') }}
        h3 {{ $t('terms.lawTitle') }}
        p {{ $t('terms.lawText') }}
        h3 {{ $t('terms.changesTitle') }}
        p {{ $t('terms.changesText') }}
        h3 {{ $t('terms.informationTitle') }}
        p(v-html="$t('terms.informationText', { email: `<a href='mailto:support@easypon.in' class='custom-link' target='_blank'>support@easypon.in</a>` })")
</template>

<script>
export default {
  name: 'TermsPage',
  metaInfo () {
    return this.$metaDefault({ robots: 'noindex, follow' })
  }
}
</script>
